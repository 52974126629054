var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relationshipauth"},[_c('mt-header',{attrs:{"title":_vm.$t('relationshipauth')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{on:{"click":function($event){return _vm.$router.back(-1)}}},[_c('img',{attrs:{"slot":"icon","src":require("@/assets/back.png"),"height":"20","width":"20"},slot:"icon"}),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":3}}),_c('div',{staticClass:"content"},[_vm._l((_vm.form.contact),function(item,index){return _c('div',{key:index + 'relativeContact'},[_c('div',{staticClass:"separator"},[_vm._v(_vm._s(_vm.$t('relativeContact'))+" "+_vm._s(_vm.form.contact.length > 1? index + 1: ''))]),_c('div',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('relationship')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('relationshipPlaceholder'),"readonly":"","state":item.relationCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.contactRelationNew, (e) => {
              item.relationCode = e.code;
              _vm.BEHAVIOR_ADD({
                id: `P04_C${(index * 3) + 1 < 10? '0' + ((index * 3) + 1): (index * 3) + 1}_S_RELATIONCODE`,
                newValue: item.relationCode
              })
            })}},model:{value:((_vm.Enum.contactRelation.filter(e => e.code == item.relationCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.contactRelation.filter(e => e.code == item.relationCode)[0]||{}), "name", $$v)},expression:"(Enum.contactRelation.filter(e => e.code == item.relationCode)[0]||{}).name"}},[(_vm.Enum.contactRelationLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('hisOrHerMoblieNumber')))]),_c('div',{staticClass:"phone"},[_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### ##'),expression:"'#### #### ##'"}],attrs:{"placeholder":_vm.$t('hisOrHerMoblieNumberPlaceholder'),"type":"tel","state":item.phoneState},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
                id: `P04_C${(index * 3) + 2 < 10? '0' + ((index * 3) + 2): (index * 3) + 2}_I_CONTACTPHONE`,
                newValue: item.phone
              })}},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}},[_c('img',{staticClass:"contact",attrs:{"src":require("../../assets/contact.png")}})])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('hisOrHerName')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('hisOrHerNamePlaceholder'),"state":item.nameState},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
              id: `P04_C${(index * 3) + 3 < 10? '0' + ((index * 3) + 3): (index * 3) + 3}_I_CONTACTNAME`,
              newValue: item.name
            })}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)])])}),_vm._l((_vm.form.otherContact),function(item,index){return _c('div',{key:index + 'otherContact'},[_c('div',{staticClass:"separator"},[_vm._v(_vm._s(_vm.$t('otherContact'))+" "+_vm._s(_vm.form.otherContact.length > 1? index + 1: ''))]),_c('div',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('relationship')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('relationshipPlaceholder'),"readonly":"","state":item.relationCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.otherContactRelationNew, (e) => {
              item.relationCode = e.code;
              _vm.BEHAVIOR_ADD({
                id: `P04_C${
                  (_vm.form.contact.length * 3) + (index * 3) + 1 < 10
                    ? '0' + ((_vm.form.contact.length * 3) + (index * 3) + 1)
                    : (_vm.form.contact.length * 3) + (index * 3) + 1
                }_S_RELATIONCODE`,
                newValue: item.relationCode
              })
            })}},model:{value:((_vm.Enum.otherContactRelation.filter(e => e.code == item.relationCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.otherContactRelation.filter(e => e.code == item.relationCode)[0]||{}), "name", $$v)},expression:"(Enum.otherContactRelation.filter(e => e.code == item.relationCode)[0]||{}).name"}},[(_vm.Enum.otherContactRelationLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('hisOrHerMoblieNumber')))]),_c('div',{staticClass:"phone"},[_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### ##'),expression:"'#### #### ##'"}],attrs:{"placeholder":_vm.$t('hisOrHerMoblieNumberPlaceholder'),"type":"tel","state":item.phoneState},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
                id: `P04_C${
                  (_vm.form.contact.length * 3) + (index * 3) + 2 < 10
                    ? '0' + ((_vm.form.contact.length * 3) + (index * 3) + 2)
                    : (_vm.form.contact.length * 3) + (index * 3) + 2
                }_I_CONTACTPHONE`,
                newValue: item.phone
              })}},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}},[_c('img',{staticClass:"contact",attrs:{"src":require("../../assets/contact.png")}})])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('hisOrHerName')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('hisOrHerNamePlaceholder'),"state":item.nameState},nativeOn:{"!blur":function($event){_vm.BEHAVIOR_ADD({
              id: `P04_C${
                (_vm.form.contact.length * 3) + (index * 3) + 3 < 10
                  ? '0' + ((_vm.form.contact.length * 3) + (index * 3) + 3)
                  : (_vm.form.contact.length * 3) + (index * 3) + 3
                }_I_CONTACTNAME`,
              newValue: item.name
            })}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)])])})],2),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }